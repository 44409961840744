import React from "react";
import COLORS from "~data/colors.json";
import { Grid, IntersectionAnimation, SliceConfig } from "~components";
import { useInView } from "react-intersection-observer";
import { hexToRGB } from "~utils/helpers";

import * as styles from "./styles.module.scss";

const ColorGrid = ({ data: { category, sliceConfig } }) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <section>
      <SliceConfig config={sliceConfig}>
        <IntersectionAnimation>
          <Grid>
            <ul className={styles.grid} ref={ref}>
              {Object.keys(COLORS).map((colorKey, i) => {
                const color = COLORS[colorKey];

                const { swatch, category: colorCategory, hex, display } = color;

                if (colorCategory !== category) {
                  return null;
                }

                const rgb = hexToRGB(hex);

                const fontColor =
                  display?.toLowerCase() === `light` ? `black` : `white`;

                return (
                  <li
                    key={i}
                    className={styles.gridItem}
                    style={{
                      background: hex,
                      color: fontColor
                    }}
                  >
                    <IntersectionAnimation
                      trigger={inView}
                      animation="fadeGrow"
                      delay={i * 50}
                    >
                      <div className={styles.gridItemContent}>
                        <p className="b2">{swatch}</p>

                        <div>
                          <p className="b2">{hex}</p>
                          <p className="b2">{`R ${rgb?.r || `0`}  G ${
                            rgb?.g || `0`
                          }  B ${rgb?.g || `0`}`}</p>
                        </div>
                      </div>
                    </IntersectionAnimation>
                  </li>
                );
              })}
            </ul>
          </Grid>
        </IntersectionAnimation>
      </SliceConfig>
    </section>
  );
};

export default ColorGrid;
