import React from "react";
import { ColorPickerItem } from "~components";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const ColorPicker = ({
  variants,
  onChange,
  itemClassName,
  square,
  vertical,
  display
}) => (
  /** ============================================================================
   * @component
   * Array of color selector buttons with layout configuration
   *
   * variant: {title, hex, id, selected}
   * @param  {Variant[]}  variants  		Available color variants
   * @param  {string}  		selectedColor	Selected color object
   * @param  {string}  		itemClassName	ClassName for color items
   * @param  {boolean}  	square				Whether the buttons are square, otherwise is a circle
   * @param  {boolean}  	vertical			Whether the buttons are stacked vertically
   * @param  {boolean}  	display				Buttons are non-interactable
   * @param  {function}  	onChange   		Fired when item is clicked, color object is passed in
   * @return {node}
   */

  <div className={cn(styles.container, { [styles.vertical]: vertical })}>
    {variants?.map((variant, i) => (
      <ColorPickerItem
        key={variant.hex}
        delay={i * 50}
        className={cn(styles.colorButton, itemClassName)}
        variant={variant}
        square={square}
        isSelected={variant?.selected}
        handleClick={onChange}
        display={display}
      />
    ))}
  </div>
);

export default ColorPicker;
