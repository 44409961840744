const fragmentShader = `
varying vec2 vUv;
uniform float time;
uniform vec2 velocity;
uniform vec3 shaderColorCenter;
uniform vec3 shaderColorRing;
uniform vec3 shaderColorEdge;
uniform vec3 shaderBackground;

float noise(vec2 p) {
  return fract(sin(dot(p, vec2(12.9898, 78.233))) * 43758.5453123);
}

void main() {
  vec4 centerColor = vec4(shaderColorCenter.rgb, 1.0);
  vec4 ringColor = vec4(shaderColorRing.rgb, 1.0);
  vec4 edgeColor = vec4(shaderColorEdge.rgb, 1.0);
  vec4 bgColor = vec4(shaderBackground.rgb, 1.0);

  vec2 circlePos = vec2(0.5, 0.5);
  float dist = distance(vUv, circlePos) * (1.0 - length(velocity) * -0.001);
  dist += 0.15;

  float alpha1 = smoothstep(0.2, 0.5, dist);
  vec4 color1 = mix(centerColor, ringColor, alpha1);

  float alpha2 = smoothstep(0.275, 0.5, dist);
  vec4 color2 = mix(color1, vec4(0.0), alpha2);

  vec2 noiseUv = (vUv - circlePos) * 20.0 + vec2(time * 0.2, 0.0);
  float noiseValue = noise(noiseUv);

  vec4 sum = vec4(0.0);
  vec2 pixelCoord = vec2(floor(vUv.x * 2048.0), floor(vUv.y * 2048.0)) / 2048.0 + 0.0001;
  vec2 offset = vec2(1.0 / 2048.0);
  for (int i = 0; i < 3; i++) {
    for (int j = 0; j < 3; j++) {
      vec2 p = pixelCoord + vec2(i, j) * offset;
      vec2 q = vec2(length(p - circlePos), atan(p.y - circlePos.y, p.x - circlePos.x));
      float n = noise(q * 20.0 + vec2(time * 0.2, 0.0));
      sum += vec4(n, n, n, 1.0);
    }
  }
  vec4 avg = sum / 9.0;

  float contrast = 0.5;
  vec4 finalColor = mix(color2, avg - contrast, 0.2);

  float edgeFactor = smoothstep(0.1, 0.7, dist);
  finalColor = mix(finalColor, edgeColor, edgeFactor);

  gl_FragColor = mix(finalColor, bgColor, smoothstep(0.5, 0.7, dist));
}
`;

export default fragmentShader;
