// extracted by mini-css-extract-plugin
export var background = "styles-module--background--10025";
export var container = "styles-module--container--d272a";
export var desktop = "1340px";
export var giant = "2200px";
export var headerAppear = "styles-module--header-appear--ce516";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var overlay = "styles-module--overlay--38556";
export var overlayBack = "styles-module--overlayBack--5b9fa";
export var overlayContent = "styles-module--overlayContent--6de42";
export var overlayHeader = "styles-module--overlayHeader--65702";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var wordmark = "styles-module--wordmark--1517a";