import React, { useState, useEffect } from "react";
import { Grid } from "~components";
import typography from "~data/typography.json";
import { dashToCamel } from "~utils/helpers";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * ASG Editor
 */
const ASGTypography = ({ onUpdate = () => {} }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [updatedType, setUpdatedType] = useState(typography);

  // ---------------------------------------------------------------------------
  // methods

  const onChange = (e) => {
    const { name, value } = e.currentTarget;

    const breakpoint = e.currentTarget.getAttribute(`data-breakpoint`);
    const fontClass = e.currentTarget.getAttribute(`data-fontclass`);

    const allTypeData = JSON.parse(JSON.stringify(updatedType));

    const breakpointIndex = allTypeData.findIndex(
      ({ min }) => min.toString() === breakpoint.toString()
    );

    const styleIndex = allTypeData[breakpointIndex].styles.findIndex(
      ({ className }) => className === fontClass
    );

    allTypeData[breakpointIndex].styles[styleIndex].attributes[
      dashToCamel(name)
    ] = value;

    setUpdatedType(allTypeData);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    onUpdate(updatedType);
  }, [updatedType]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Grid>
      <div className={styles.typography}>
        {updatedType.map(({ min, max, styles: breakpointStyles }) => (
          <ul key={`type-table-${min}-${max}`}>
            <li className={styles.typographyListTable}>
              <h2 className="h2">
                {min}-{max === -1 ? `1920px+` : `${max}px`}
              </h2>

              {breakpointStyles.map(
                ({ id: styleId, className, attributes }) => (
                  <li
                    key={`type-table-row-${styleId}`}
                    className={styles.typographyListTableRow}
                  >
                    <h3
                      style={{ ...attributes }}
                      className={styles.typographyListTableExample}
                    >
                      {styleId}
                    </h3>

                    <label htmlFor="font-size">
                      <span className="b2">font-size: </span>

                      <input
                        name="font-size"
                        data-breakpoint={min}
                        data-fontclass={className}
                        onChange={onChange}
                        type="text"
                        defaultValue={attributes.fontSize}
                      />
                    </label>

                    <label htmlFor="line-height">
                      <span className="b2">line-height: </span>

                      <input
                        name="line-height"
                        data-breakpoint={min}
                        data-fontclass={className}
                        onChange={onChange}
                        type="text"
                        defaultValue={attributes.lineHeight}
                      />
                    </label>

                    <label htmlFor="letter-spacing">
                      <span className="b2">letter-spacing: </span>

                      <input
                        name="letter-spacing"
                        data-breakpoint={min}
                        data-fontclass={className}
                        onChange={onChange}
                        type="text"
                        defaultValue={attributes.letterSpacing}
                      />
                    </label>
                  </li>
                )
              )}
            </li>
          </ul>
        ))}
      </div>
    </Grid>
  );
};

export default ASGTypography;
