import React from "react";
import { Accordion } from "~components";
import { usePlayground } from "~hooks";

const PgAccordion = () => {
  const { renderPlayground } = usePlayground();

  return (
    <>
      {renderPlayground(
        <>
          <Accordion
            title="Is the tropical belt shifting?"
            content="The tropical belt, also known as the intertropical convergence zone (ITCZ), is a band of low pressure that circles the Earth near the equator. The position of the ITCZ is influenced by factors such as atmospheric circulation and ocean currents, as well as factors such as climate change. Some studies have suggested that the ITCZ may be shifting due to climate change, however more research is needed to fully understand the underlying causes and mechanisms of this shift."
          />
          <Accordion
            title="Who is canonically the most powerful teenage mutant ninja turtle?"
            content="In terms of physical strength and fighting skills, Raphael is often portrayed as the strongest and most aggressive, but each turtle has their own unique strengths and abilities."
          />
        </>
      )}
    </>
  );
};

export default PgAccordion;
