import React from "react";
import cn from "classnames";
import { Link } from "gatsby";
import * as styles from "./styles.module.scss";

const PageLink = ({ disabled, isActivePage, title, slug }) => (
  <div className="h3">
    {disabled ? (
      <div className={styles.disabledLink}>{title}</div>
    ) : (
      <Link
        className={cn(styles.listItem, {
          [styles.activePage]: isActivePage
        })}
        to={`/${slug.current}`}
      >
        {title}
      </Link>
    )}
  </div>
);

export default PageLink;
