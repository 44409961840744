import React from "react";
import { SVG } from "~components";
import cn from "classnames";

import * as styles from "./styles.module.scss";

const Quantity = ({ value, handleDecrement, handleIncrement, max }) => {
  /** ============================================================================
   * @component
   * Displays quantity of an item and allows user to update
   *
   * @param  {number}  		value 						Value from parent
   * @param  {number}  		max 							Maximum value
   * @param  {function}  	handleDecrement 	Passed to minus button, should decrement value
   * @param  {function}  	handleIncrement   Passed to plus button, should increment value
   * @return {node}
   */

  const isMin = value === 1;
  const isMax = max ? value === max : false;

  return (
    <div className={styles.container}>
      <button
        aria-label="Decrease value by 1"
        onClick={handleDecrement}
        type="button"
        className={cn(styles.button, { [styles.inactive]: isMin })}
        disabled={isMin}
      >
        <SVG svg="minus" className={styles.icon} />
      </button>
      <span className="b1">{value}</span>
      <button
        aria-label="Increase value by 1"
        onClick={handleIncrement}
        type="button"
        className={cn(styles.button, { [styles.inactive]: isMax })}
        disabled={isMax}
      >
        <SVG svg="plus" className={styles.icon} />
      </button>
    </div>
  );
};

export default Quantity;
