import React, { useState } from "react";
import typography from "~data/typography.json";
import { SliceConfig, IntersectionAnimation, Grid } from "~components";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import * as styles from "./styles.module.scss";

const TypeTable = ({ data: { subheadingStyle, sliceConfig, previewText } }) => {
  const getBreakpointString = (breakpointObject) =>
    `${breakpointObject.min}px - ${
      breakpointObject.max === -1 ? `1920px+` : `${breakpointObject.max}px`
    }`;

  const [breakpoint, setBreakpoint] = useState(0);

  const getPreviewText = (styleId) =>
    previewText.find((text) => text.styleId === styleId)?.text;

  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <section ref={ref} className={styles.container}>
      <SliceConfig config={sliceConfig}>
        <Grid>
          <div className={styles.content}>
            <div className={cn(styles.toggleButtons, `caption`)}>
              {typography.map((breakpointObject, index) => (
                <button
                  className={cn({
                    [styles.activeOption]: breakpoint === index
                  })}
                  type="button"
                  onClick={() => setBreakpoint(index)}
                >
                  {getBreakpointString(breakpointObject)}
                </button>
              ))}
            </div>

            <div className={cn(styles.row, subheadingStyle?.style || `b2`)}>
              <div className={styles.narrowColumn}>Specimen</div>
              <div className={styles.wideColumn}>Preview</div>
              <div className={cn(styles.narrowColumn, styles.colRightAlign)}>
                Font-size
              </div>
              <div className={cn(styles.narrowColumn, styles.colRightAlign)}>
                Line-height
              </div>
              <div className={cn(styles.narrowColumn, styles.colRightAlign)}>
                Letter-spacing
              </div>
            </div>

            <div key={breakpoint}>
              {typography[breakpoint].styles.map(
                ({ id: styleId, attributes }, j) => (
                  <IntersectionAnimation
                    trigger={inView}
                    key={j}
                    delay={j * 100}
                    className={styles.rowContainer}
                  >
                    <li className={styles.row}>
                      <div className={cn(styles.narrowColumn, `b2`)}>
                        {styleId}
                      </div>
                      <div
                        className={styles.wideColumn}
                        style={{ ...attributes }}
                      >
                        {getPreviewText(styleId)}
                      </div>

                      <div
                        className={cn(
                          styles.narrowColumn,
                          styles.colRightAlign,
                          `b2`
                        )}
                      >
                        {attributes.fontSize}
                      </div>
                      <div
                        className={cn(
                          styles.narrowColumn,
                          styles.colRightAlign,
                          `b2`
                        )}
                      >
                        {attributes.lineHeight}
                      </div>
                      <div
                        className={cn(
                          styles.narrowColumn,
                          styles.colRightAlign,
                          `b2`
                        )}
                      >
                        {attributes.letterSpacing || `0em`}
                      </div>
                    </li>
                  </IntersectionAnimation>
                )
              )}
            </div>
          </div>
        </Grid>
      </SliceConfig>
    </section>
  );
};

export default TypeTable;
