import React from "react";
import {
  IntersectionAnimation,
  SliceConfig,
  TypeGrid as TypeGridComponent,
  Grid
} from "~components";
import * as styles from "./styles.module.scss";

const TypeGrid = ({ data: { toggleButtonStyle, sliceConfig } }) => (
  <section>
    <SliceConfig config={sliceConfig}>
      <Grid>
        <IntersectionAnimation className={styles.content}>
          <TypeGridComponent
            toggleButtonStyle={toggleButtonStyle}
            backgroundColor={sliceConfig.backgroundColor?.hex}
          />
        </IntersectionAnimation>
      </Grid>
    </SliceConfig>
  </section>
);

export default TypeGrid;
