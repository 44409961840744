import React from "react";
import {
  SliceConfig,
  Grid,
  PortableTextRenderer,
  IntersectionAnimation
} from "~components";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const HeadlineTextRow = ({ data: { headline, _rawText, sliceConfig } }) => (
  <section className={styles.container}>
    <SliceConfig config={sliceConfig}>
      <Grid>
        {headline && (
          <h3 className={cn(`h3`, styles.headline)}>
            <IntersectionAnimation>{headline}</IntersectionAnimation>
          </h3>
        )}

        {_rawText && (
          <div className={styles.text}>
            <IntersectionAnimation delay={150}>
              <PortableTextRenderer rawText={_rawText} />
            </IntersectionAnimation>
          </div>
        )}
      </Grid>
    </SliceConfig>
  </section>
);

export default HeadlineTextRow;
