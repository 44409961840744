import React from "react";
import {
  SliceConfig,
  Grid,
  PageLink,
  IntersectionAnimation
} from "~components";
import { usePageNavigation, useApp } from "~hooks";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const Menulist = ({ data: { sliceConfig } }) => {
  const { pageCategories } = usePageNavigation();
  const { pathname } = useApp();

  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <nav ref={ref}>
      <SliceConfig config={sliceConfig}>
        <Grid>
          <div className={styles.content}>
            {pageCategories?.map((category, i) => (
              <IntersectionAnimation delay={i * 300} trigger={inView}>
                <div key={category.id} className={styles.categoryGroup}>
                  <Grid noXPadding>
                    <h4 className={cn(`b1`, styles.categoryTitle)}>
                      {category.title}
                    </h4>

                    <ul className={styles.links}>
                      {category.pages.map((page) => {
                        const isActivePage = pathname?.includes(
                          page.slug.current
                        );

                        return (
                          <li key={page.id} className={styles.listItem}>
                            <PageLink
                              disabled={page.disabled}
                              title={page.title}
                              isActivePage={isActivePage}
                              slug={page.slug}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </Grid>
                </div>
              </IntersectionAnimation>
            ))}
          </div>
        </Grid>
      </SliceConfig>
    </nav>
  );
};

export default Menulist;
