// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--919e3";
export var appearDown = "styles-module--appear-down--9b0a5";
export var appearLeft = "styles-module--appear-left--9aa76";
export var appearRight = "styles-module--appear-right--2b3d3";
export var appearUp = "styles-module--appear-up--f483a";
export var container = "styles-module--container--cce7e";
export var content = "styles-module--content--f13d2";
export var contentVisible = "styles-module--contentVisible--e40f3";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";