import React from "react";
import { PortableText } from "@portabletext/react";
import * as styles from "./styles.module.scss";

const customComponents = {
  block: {
    normal: ({ children }) => <p className="b1">{children}</p>,
    d1: ({ children }) => <h2 className="d1">{children}</h2>,
    h1: ({ children }) => <h2 className="h1">{children}</h2>,
    h2: ({ children }) => <h3 className="h2">{children}</h3>,
    h3: ({ children }) => <h4 className="h3">{children}</h4>,
    b1: ({ children }) => <p className="b1">{children}</p>,
    b2: ({ children }) => <p className="b2">{children}</p>,
    caption: ({ children }) => <p className="caption">{children}</p>,
    button: ({ children }) => <p className="button">{children}</p>,
    underline: ({ children }) => <p className="underline">{children}</p>
  }
};

const PortableTextRenderer = ({ rawText }) => (
  <div className={styles.container}>
    <PortableText value={rawText} components={customComponents} />
  </div>
);

export default PortableTextRenderer;
