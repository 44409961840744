/* eslint-disable react/no-danger */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-extraneous-dependencies */

import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import cn from "classnames";
import { useWindowDimensions } from "~hooks";
import { Button } from "~components";

import * as styles from "./styles.module.scss";

const ALT_FALLBACK = `YIRRAMBOI`;

const BlockContentRenderer = ({ blocks }) => {
  const { isDesktop } = useWindowDimensions();

  const serializers = {
    types: {
      block: ({ node, children }) => {
        // todo: restore when we need line breaks
        // children = children.map((child) => {
        //   if (child === `---`) {
        //     return `<div class="${styles.contentBorder}"></div>`;
        //   }

        //   return child;
        // });

        switch (node.style) {
          case `d1`:
            return <h2 className="d1">{children}</h2>;
          case `h1`:
            return <h2 className="h1">{children}</h2>;
          case `h2`:
            return <h2 className="h2">{children}</h2>;
          case `h3`:
            return <h3 className="h3">{children}</h3>;
          case `h4`:
            return <h4 className="h4">{children}</h4>;
          case `h5`:
            return <h5 className="h5">{children}</h5>;
          case `h6`:
            return <h6 className="h6">{children}</h6>;
          case `b1`:
            return <p className="b1">{children}</p>;
          case `b2`:
            return <p className="b2">{children}</p>;
          case `b3`:
            return <p className="b3">{children}</p>;
          case `caption`:
            return <p className="caption">{children}</p>;
          default:
            return (
              <p
                className={
                  isDesktop ? cn(styles.text, `b1`) : cn(styles.text, `h3`)
                }
              >
                {children}
              </p>
            );
        }
      },
      altImage: (props) => {
        if (!props?.node?.asset?._ref) {
          return null;
        }

        const builder = imageUrlBuilder({
          projectId: `g0ai99p1`,
          dataset: `production`
        });

        const urlFor = (source, width) =>
          builder.image(source).width(width).format(`webp`).url();

        const sizes = [400, 800, 1200, 1600];

        const srcset = sizes
          .map((size) => `${urlFor(props?.node?.asset?._ref, size)} ${size}w`)
          .join(`, `);

        return (
          <figure className={cn(styles.figure)}>
            <img
              src={urlFor(props?.node?.asset?._ref, 800)}
              srcSet={srcset}
              sizes="(max-width: 800px) 100vw, 800px"
              alt={props?.node?.alt || ALT_FALLBACK}
            />
          </figure>
        );
      }
    },
    marks: {
      link: ({ mark, children }) => (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
      button: ({ mark, children }) => (
        <a href={mark.href} className={styles.buttonAnchor}>
          <Button
            className={styles.button}
            color={mark.colorKey}
            transparent={mark?.transparent}
            iconRight={mark?.asDownload ? `Download` : null}
          >
            <span className="button-text">{children}</span>
          </Button>
        </a>
      )
    },
    lists: {
      number: ({ children }) => <ol>{children}</ol>,
      bullet: ({ children }) => <ul>{children}</ul>,
      listItem: ({ children }) => <li>{children}</li>
    }
  };

  return (
    <div className={styles.container}>
      <BlockContent blocks={blocks} serializers={serializers} />
    </div>
  );
};

export default BlockContentRenderer;
