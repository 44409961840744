// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--3a8ba";
export var appearDown = "styles-module--appear-down--e4079";
export var appearLeft = "styles-module--appear-left--b5028";
export var appearRight = "styles-module--appear-right--e040b";
export var appearUp = "styles-module--appear-up--f13f9";
export var container = "styles-module--container--da114";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var nextButton = "styles-module--nextButton--95593";
export var prevButton = "styles-module--prevButton--3aeae";
export var separator = "styles-module--separator--b130c";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";