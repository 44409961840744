/* eslint-disable no-return-assign */

import React, { useEffect, useRef } from "react";
import cn from "classnames";
import gsap from "gsap";

import * as styles from "./styles.module.scss";

const PixelPuzzle = ({ backgroundColor, boxColor1, boxColor2 }) => {
  const boxesRef = useRef([]);
  const containerRef = useRef(null);

  useEffect(() => {
    const activeTimeline = gsap.timeline();

    const STEP = `0.1`;

    activeTimeline
      .addLabel(`start`)
      .add(() => {
        gsap.set(boxesRef.current[0], { transformOrigin: `left center` });
        gsap.set(boxesRef.current[1], { transformOrigin: `right center` });
        gsap.set(boxesRef.current[2], { transformOrigin: `right center` });
        gsap.set(boxesRef.current[3], { transformOrigin: `left center` });
        gsap.set(boxesRef.current[4], { transformOrigin: `right center` });
        gsap.set(boxesRef.current[5], { transformOrigin: `left center` });

        gsap.to(boxesRef.current, { scaleX: 0, duration: 0 });
      }, `start`)
      .add(() => {
        gsap.to(boxesRef.current[0], {
          scaleX: 1,
          duration: 0.5,
          ease: `power2`
        });
      }, `+=${STEP}`)
      .add(() => {
        gsap.to(boxesRef.current[1], {
          scaleX: 1,
          duration: 1,
          ease: `power2`
        });
      }, `+=${STEP}`)
      .add(() => {
        gsap.to(boxesRef.current[2], {
          scaleX: 1,
          duration: 1,
          ease: `power2`
        });
      }, `+=${STEP}`)
      .add(() => {
        gsap.to(boxesRef.current[3], {
          scaleX: 1,
          duration: 0.5,
          ease: `power2`
        });
      }, `+=${STEP}`)
      .add(() => {
        gsap.to(boxesRef.current[4], {
          scaleX: 1,
          duration: 1,
          ease: `power2`
        });
      }, `+=${STEP}`)
      .add(() => {
        gsap.to(boxesRef.current[5], {
          scaleX: 1,
          duration: 1,
          ease: `power2`
        });
      }, `+=${STEP}`);

    const onMouseMove = (e) => {
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      const containerCenterX = containerWidth / 2;
      const containerCenterY = containerHeight / 2;
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const normalizedMouseX =
        (mouseX - containerCenterX) / (containerWidth / 2);
      const normalizedMouseY =
        (mouseY - containerCenterY) / (containerHeight / 2);

      gsap.to(boxesRef.current[0], {
        x: -normalizedMouseX * 50,
        y: normalizedMouseY * 50,
        duration: 0.2
      });

      gsap.to(boxesRef.current[1], {
        x: -normalizedMouseX * 100,
        y: normalizedMouseY * 100,
        duration: 0.2
      });

      gsap.to(boxesRef.current[2], {
        x: -normalizedMouseX * 150,
        y: normalizedMouseY * 150,
        duration: 0.2
      });

      gsap.to(boxesRef.current[3], {
        x: normalizedMouseX * 160,
        y: -normalizedMouseY * 160,
        duration: 0.2
      });

      gsap.to(boxesRef.current[4], {
        x: normalizedMouseX * 240,
        y: -normalizedMouseY * 240,
        duration: 0.2
      });

      gsap.to(boxesRef.current[5], {
        x: normalizedMouseX * 140,
        y: -normalizedMouseY * 140,
        duration: 0.2
      });
    };

    containerRef.current.addEventListener(`mousemove`, onMouseMove);

    return () => {
      containerRef.current?.removeEventListener(`mousemove`, onMouseMove);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={styles.container}
      style={{ backgroundColor }}
    >
      <div
        ref={(el) => (boxesRef.current[0] = el)}
        className={cn(styles.box, styles.box1)}
        style={{ backgroundColor: boxColor1 }}
      />

      <div
        ref={(el) => (boxesRef.current[1] = el)}
        className={cn(styles.box, styles.box2)}
        style={{ backgroundColor: boxColor1 }}
      />

      <div
        ref={(el) => (boxesRef.current[2] = el)}
        className={cn(styles.box, styles.box3)}
        style={{ backgroundColor: boxColor1 }}
      />

      <div
        ref={(el) => (boxesRef.current[3] = el)}
        className={cn(styles.box, styles.box4)}
        style={{ backgroundColor: boxColor2 }}
      />

      <div
        ref={(el) => (boxesRef.current[4] = el)}
        className={cn(styles.box, styles.box5)}
        style={{ backgroundColor: boxColor2 }}
      />

      <div
        ref={(el) => (boxesRef.current[5] = el)}
        className={cn(styles.box, styles.box6)}
        style={{ backgroundColor: boxColor2 }}
      />
    </div>
  );
};

export default PixelPuzzle;
