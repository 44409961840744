// extracted by mini-css-extract-plugin
export var button = "styles-module--button--91580";
export var buttonAnchor = "styles-module--buttonAnchor--4ad64";
export var container = "styles-module--container--0b010";
export var contentBorder = "styles-module--contentBorder--e89fd";
export var desktop = "1340px";
export var figure = "styles-module--figure--62762";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";