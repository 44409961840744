import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const ColorPickerItem = ({
  variant,
  square,
  delay,
  handleClick,
  isSelected,
  display,
  className
}) => (
  /** ============================================================================
   * @component
   * Button to select product color
   *
   * @typedef {{
   * 	title: string,
   * 	hex: string
   * }} Color
   *
   * @param  {Color}  		variant				Color hex + title
   * @param  {string}  		className			Used for additional styling config
   * @param  {number}  		delay					Transition delay for stagger effect
   * @param  {boolean}  	square				Whether the item is a square, otherwise is a circle
   * @param  {boolean}  	isSelected		Whether the item is selected
   * @param  {boolean}  	display		 	  Colors are non-interactable
   * @param  {function}  	handleClick   Fired when item is clicked, hex value is passed in
   * @return {node}
   */
  <>
    {display ? (
      <div
        style={{ background: variant.hex, transitionDelay: `${delay}ms` }}
        className={cn(styles.button, className, {
          [styles.isSquare]: square,
          [styles.isSelected]: isSelected
        })}
      />
    ) : (
      <button
        onClick={() => handleClick && handleClick(variant)}
        style={{ background: variant.hex }}
        className={cn(styles.button, className, {
          [styles.isSquare]: square,
          [styles.isSelected]: isSelected
        })}
        type="button"
        aria-label={`Select ${variant?.title} color`}
      />
    )}
  </>
);

export default ColorPickerItem;
