/* eslint-disable import/no-extraneous-dependencies */

import React, { Suspense, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { useWindowDimensions } from "~hooks";
import { NoiseSpotlightHTML, NoiseSpotlightMesh } from "~components";

import * as styles from "./styles.module.scss";

const NoiseSpotlight = ({
  shouldRender = true,
  centerColor = `#F19D72`,
  ringColor = `#C3C3F3`,
  edgeColor = `#000419`,
  backgroundColor = `#000419`
}) => {
  const { windowSize } = useWindowDimensions();
  const canvasRef = useRef();

  return (
    <>
      <div ref={canvasRef} className={styles.canvas}>
        {shouldRender && canvasRef?.current && (
          <Canvas
            orthographic
            shadows
            colorManagement
            camera={{ zoom: 1, position: [0, 0, 1] }}
            style={{ background: backgroundColor || `#000419` }}
          >
            <ambientLight intensity={0.5} />

            <pointLight
              position={[0, 10, 30]}
              rotation={[0, 90, 0]}
              intensity={1}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              castShadow
            />

            <Suspense>
              <NoiseSpotlightMesh
                canvas={canvasRef.current}
                dimensions={windowSize}
                centerColor={centerColor}
                ringColor={ringColor}
                edgeColor={edgeColor}
                backgroundColor={backgroundColor}
              />

              {/* todo: get true lighting and shadows working */}
              {/* <NoiseSpotlightHTML /> */}
            </Suspense>
          </Canvas>
        )}
      </div>
    </>
  );
};

export default NoiseSpotlight;
