import { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const useSanityPages = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [pages, setPages] = useState(null);

  const { allSanityPage } = useStaticQuery(
    graphql`
      query SanityPages {
        allSanityPage {
          edges {
            node {
              title
              slug {
                current
              }
              disabled

              #
              # sync this to slices available both locally and in lam-dbp-slices

              pagebuilder {
                ...PagebuilderFragment
              }
            }
          }
        }
      }
    `
  );

  // ---------------------------------------------------------------------------
  // methods

  const getPageBySlug = (searchSlug) =>
    pages?.[0]
      ? pages.find(
          ({ slug }) =>
            slug.current === searchSlug ||
            `/${slug.current}` === searchSlug ||
            `${slug.current}/` === searchSlug ||
            `/${slug.current}/` === searchSlug
        )
      : null;

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setPages(allSanityPage.edges.map(({ node }) => node));
  }, [allSanityPage]);

  // ---------------------------------------------------------------------------

  return {
    pages,
    getPageBySlug
  };
};

export default useSanityPages;
