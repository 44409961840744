import React from "react";
import {
  Grid,
  HTMLVideo,
  IntersectionAnimation,
  SliceConfig
} from "~components";

import * as styles from "./styles.module.scss";

const SimpleVideo = ({ data: { videoURL, sliceConfig } }) => {
  if (!videoURL) {
    return null;
  }

  return (
    <section>
      <IntersectionAnimation>
        <SliceConfig config={sliceConfig}>
          <div className={styles.container}>
            <Grid>
              <div className={styles.videoContainer}>
                <HTMLVideo
                  className={styles.video}
                  src={videoURL}
                  autoPlay
                  muted
                  loop
                  playsInline
                />
              </div>
            </Grid>
          </div>
        </SliceConfig>
      </IntersectionAnimation>
    </section>
  );
};

export default SimpleVideo;
