import React from "react";
import { SVG } from "~components";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const TextInput = ({
  placeholder,
  id,
  value,
  label,
  hint,
  warningMessage,
  hasSearchIcon,
  hasError,
  required,
  disabled,
  onClick,
  onChange,
  className,
  textarea,
  onEnter,
  onBlur
}) => {
  /** ============================================================================
   * @component
   * Text input form field
   * Can be standalone (pass in onClick) or part of larger form
   *
   * @param  {string}    	className  			For additional styling config
   * @param  {string}    	placeholder  		Placeholder text
   * @param  {string}    	id           		Used to connect with label for a11y
   * @param  {string}  		value 					Control value from parent
   * @param  {string}   	label        		Text which displays in top left corner
   * @param  {string}    	hint      			Text which displays in top right corner
   * @param  {string} 		warningMessage  Shows in bottom left next to warning icon
   * @param  {boolean} 		hasSearchIcon  	Whether to display the search icon
   * @param  {boolean} 		required  			Whether to render the '*' next to label
   * @param  {boolean} 		hasError  			Toggles the error state
   * @param  {boolean} 		disabled  			Toggles the disabled state
   * @param  {boolean} 		isDarkTheme  		Toggles inverted color scheme
   * @param  {boolean} 		textarea  			Renders multiline textarea
   * @param  {function} 	onClick  				Renders the inline submit button
   * @param  {function} 	onChange  			Callback which gets passed e.target.value when updated
   * @param  {function} 	onEnter  				Called when user presses Enter key, used for standalone inputs
   * @param  {function} 	onBlur  				Runs when input loses focus
   * @return {node}
   */

  const MAX_LENGTH = 300;

  const handleChange = (e) => {
    if (onChange) onChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (!onEnter) return;
    if (e.key === `Enter`) onEnter();
  };

  const warningIconColor = `var(--color-black-40)`;

  const InputElement = textarea ? `textarea` : `input`;

  return (
    <div
      className={cn(styles.container, className, {
        [styles.error]: hasError,
        [styles.disabled]: disabled,
        [styles.hasSearch]: hasSearchIcon,
        [styles.hasArrow]: onClick
      })}
    >
      {(label || hint || required) && (
        <div className={styles.topText}>
          <div>
            {(label || required) && (
              <label className={cn(`caption`, styles.label)} htmlFor={id}>
                {required && `* `}
                {label}
              </label>
            )}
          </div>
          <div>
            {hint && <span className={cn(`caption`, styles.hint)}>{hint}</span>}
          </div>
        </div>
      )}
      <div className={styles.inputContainer}>
        {hasSearchIcon && (
          <div className={styles.iconContainer}>
            <SVG className={styles.searchIcon} svg="LookingGlass" />
          </div>
        )}
        <InputElement
          maxLength={MAX_LENGTH}
          tabIndex={disabled ? -1 : 0}
          id={id}
          className={cn(`b2`, styles.input, {
            [styles.textarea]: textarea,
            [styles.hasSearch]: hasSearchIcon,
            [styles.hasArrow]: onClick
          })}
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          onKeyDown={handleKeyDown}
          onBlur={() => onBlur && onBlur()}
        />
        {onClick && (
          <div className={styles.buttonContainer}>
            <button
              tabIndex={disabled ? -1 : 0}
              type="submit"
              aria-label="Submit"
              onClick={(e) => {
                e.preventDefault();
                onClick();
              }}
              className={styles.button}
            >
              <SVG
                className={styles.icon}
                svg="ArrowRight"
                style={{
                  color: `var(--color-black)`
                }}
              />
            </button>
          </div>
        )}
      </div>
      {warningMessage && (
        <span className={cn(`caption`, styles.warning)}>
          <SVG
            svg="WarningTriangle"
            className={styles.icon}
            style={{
              color: hasError ? `var(--color-ux-error)` : warningIconColor
            }}
          />

          {warningMessage}
        </span>
      )}
    </div>
  );
};

export default TextInput;
