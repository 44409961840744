// extracted by mini-css-extract-plugin
export var activeOption = "styles-module--activeOption--16174";
export var breakpointSet = "styles-module--breakpointSet--c3423";
export var colRightAlign = "styles-module--colRightAlign--fd77e";
export var container = "styles-module--container--aa501";
export var content = "styles-module--content--534b3";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var narrowColumn = "styles-module--narrowColumn--907df";
export var row = "styles-module--row--490df";
export var rowContainer = "styles-module--rowContainer--ae34b";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var toggleButtons = "styles-module--toggleButtons--c97fd";
export var wideColumn = "styles-module--wideColumn--4934b";