/* eslint-disable no-return-assign */

import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Box } from "@react-three/drei";

const PixelPuzzle = ({ scale }) => {
  const boxesRef = useRef([]);

  useFrame(({ clock }) => {
    const time = clock.elapsedTime;

    boxesRef.current[0].scale.x = Math.min(1, time / 0.5);
    boxesRef.current[1].scale.x = Math.min(1, (time - 0.1) / 1);
    boxesRef.current[2].scale.x = Math.min(1, (time - 0.2) / 1);
    boxesRef.current[3].scale.x = Math.min(1, (time - 0.3) / 0.5);
    boxesRef.current[4].scale.x = Math.min(1, (time - 0.4) / 1);
    boxesRef.current[5].scale.x = Math.min(1, (time - 0.5) / 1);
  });

  return (
    <group scale={[scale, scale, scale]}>
      <Box
        ref={(el) => (boxesRef.current[0] = el)}
        args={[1, 1, 1]}
        position={[-1.5, 0, 0]}
        pivot={[0, 0.5, 0]}
      >
        <meshStandardMaterial color="#ff8731" />
      </Box>

      <Box
        ref={(el) => (boxesRef.current[1] = el)}
        args={[2, 1, 1]}
        position={[1, 1.5, 0]}
        pivot={[1, 0, 0]}
      >
        <meshStandardMaterial color="#ff8731" />
      </Box>

      <Box
        ref={(el) => (boxesRef.current[2] = el)}
        args={[2, 0.5, 1]}
        position={[1, -1, 0]}
        pivot={[1, 1, 0]}
      >
        <meshStandardMaterial color="#ff8731" />
      </Box>

      <Box
        ref={(el) => (boxesRef.current[3] = el)}
        args={[0.4, 0.85, 1]}
        position={[-0.25, 0.5, 0]}
        pivot={[0, 0.5, 0]}
      >
        <meshStandardMaterial color="#A75551" />
      </Box>

      <Box
        ref={(el) => (boxesRef.current[4] = el)}
        args={[1, 1, 1]}
        position={[1, -1.5, 0]}
        pivot={[1, 1, 0]}
      >
        <meshStandardMaterial color="#A75551" />
      </Box>

      <Box
        ref={(el) => (boxesRef.current[5] = el)}
        args={[1, 0.3, 1]}
        position={[1, 1, 0]}
        pivot={[1, 0, 0]}
      >
        <meshStandardMaterial color="#A75551" />
      </Box>
    </group>
  );
};

export default PixelPuzzle;
