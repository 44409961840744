import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import cn from "classnames";
import { Grid, SVG } from "~components";

import * as THREE from "three";
import * as styles from "./styles.module.scss";

const NoiseSpotlightHTML = () => {
  const meshRef = useRef();

  useFrame(() => {
    meshRef.current.rotation.z += 0.01;
  });

  return (
    <>
      <mesh ref={meshRef} castShadow receiveShadow>
        <Html style={{ background: `transparent` }} fullscreen>
          <div className={cn(styles.overlay)}>
            <Grid>
              <div className={cn(styles.overlayHeader)}>
                <SVG svg="wordmark" className={styles.wordmark} />
                <h1 className={cn(`h2`)}>Toolkit™</h1>
              </div>

              <div className={cn(styles.overlayContent)}>
                <p className={cn(`h2`)}>
                  Lucid reimagine services that humans use every day with
                  technology that optimises for efficiency and transparency
                  because seeing is believing.
                </p>
              </div>
            </Grid>
          </div>
        </Html>

        <meshStandardMaterial attach="material" color="white" />
      </mesh>
    </>
  );
};

export default NoiseSpotlightHTML;
