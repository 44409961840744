// extracted by mini-css-extract-plugin
export var container = "styles-module--container--34c0d";
export var dark = "styles-module--dark--12201";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--eb0aa";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--15b1b";
export var listItem = "styles-module--listItem--58a49";
export var listItemDisabled = "styles-module--listItemDisabled--6db95";
export var listLabel = "styles-module--listLabel--5f6cb";
export var logo = "styles-module--logo--1ef34";
export var logoContainer = "styles-module--logoContainer--cca19";
export var menu = "styles-module--menu--6807d";
export var menuContainer = "styles-module--menuContainer--01226";
export var menuIcon = "styles-module--menuIcon--d3537";
export var menuLine = "styles-module--menuLine--f746d";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var submenu = "styles-module--submenu--8147c";
export var submenuActive = "styles-module--submenuActive--1ec57";
export var submenuContainer = "styles-module--submenuContainer--131b6";
export var submenuList = "styles-module--submenuList--4d651";
export var tablet = "769px";