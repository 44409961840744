import React, { useEffect, useState } from "react";
import cn from "classnames";
import gsap from "gsap";
import { useKeyPress, usePageNavigation } from "~hooks";
import { Grid, Link } from "~components";

import { ReactComponent as Logo } from "~assets/svg/wordmark.svg";
import * as styles from "./styles.module.scss";

const Header = () => {
  // ---------------------------------------------------------------------------
  // hooks

  const escapePressed = useKeyPress(`Escape`);
  const { pageCategories } = usePageNavigation();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [menuActive, setMenuActive] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (menuActive) {
      gsap.fromTo(
        `.header-list-item`,
        {
          opacity: 0
        },
        {
          opacity: 1,
          duration: 0.75,
          ease: `power2`,
          delay: 0.2,
          stagger: {
            each: 0.035,
            from: `start`
          }
        }
      );
    } else {
      gsap.to(`.header-list-item`, {
        opacity: 0,
        duration: 0.2,
        ease: `power2`
      });
    }
  }, [menuActive]);

  // useEffect(() => {
  //   setMenuActive(false);
  // }, [scrollTop]);

  useEffect(() => {
    if (escapePressed) {
      setMenuActive(false);
    }
  }, [escapePressed]);

  // ---------------------------------------------------------------------------
  // render

  const innerContent = (
    <Grid
      className={cn(styles.grid, {
        [styles.light]: !menuActive ? styles.light : null,
        [styles.dark]: menuActive ? styles.dark : null
      })}
    >
      <div className={cn(styles.logoContainer)}>
        <Link to="/">
          <Logo className={cn(styles.logo)} />
        </Link>
      </div>

      <div className={cn(styles.menuContainer)}>
        <button
          type="button"
          className={cn(styles.menu)}
          onClick={() => setMenuActive(!menuActive)}
        >
          <div className={cn(styles.menuIcon)}>
            <div className={cn(styles.menuLine)} />
            <div className={cn(styles.menuLine)} />
            <div className={cn(styles.menuLine)} />
          </div>
        </button>
      </div>
    </Grid>
  );

  return (
    <header className={cn(styles.container)}>
      {innerContent}

      <div className={cn(styles.submenuContainer)}>
        <nav
          className={cn(styles.submenu, {
            [styles.submenuActive]: menuActive ? styles.submenuActive : null
          })}
        >
          <Grid>
            {pageCategories.map((category) => (
              <div key={category.id} className={cn(styles.submenuList)}>
                <h4 className={cn(styles.listLabel, `caption`)}>
                  {category.title}
                </h4>

                <ul>
                  {category.pages.map(({ title, slug, disabled }, index) => {
                    const className = disabled
                      ? cn(styles.listItem, styles.listItemDisabled, `h3`)
                      : cn(styles.listItem, `h3`);

                    return (
                      <li className={className} key={slug.current}>
                        <Link
                          to={`/${slug.current}`}
                          className="header-list-item"
                          style={{ transitionDelay: `${index * 0.1}ms` }}
                          onClick={() => setMenuActive(false)}
                        >
                          <span>{title}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </Grid>
        </nav>
      </div>
    </header>
  );
};

export default Header;
