import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import gsap from "gsap";
import { useScroll, useWindowDimensions } from "~hooks";
import { Grid, NoiseSpotlight, SVG } from "~components";

import * as styles from "./styles.module.scss";

const Spotlight = ({ data: { text, textColor } }) => {
  const [pos, setPos] = useState({ x: -100, y: -100 });

  const { scrollY } = useScroll();

  const updatePos = (e) => {
    setPos({ x: e.clientX, y: e.clientY });
  };
  const { isDesktop, windowSize } = useWindowDimensions();

  const containerRef = useRef();

  const [cursorTimeout, setCursorTimeout] = useState(null);
  const [prevPos, setPrevPos] = useState({
    x: 0,
    y: 0
  });

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!isDesktop) {
      return () => {};
    }

    if (cursorTimeout) {
      clearTimeout(cursorTimeout);
    }

    const transition = {
      duration: 3.5,
      ease: `expo.out`
    };

    const cursorSize = windowSize?.width ? parseInt(windowSize.width / 4) : 300;

    const posX = pos.x;
    const posY = pos.y + scrollY;

    let velocityX = posX - prevPos.x;
    let velocityY = posY - prevPos.y;

    if (velocityX < 0) {
      velocityX = -velocityX;
    }

    if (velocityY < 0) {
      velocityY = -velocityY;
    }

    let scale = (velocityX + velocityY) / 75;

    if (scale < 1) {
      scale = 1;
    } else if (scale > 2.5) {
      scale = 2.5;
    }

    gsap.to(`.spotlight-clipper`, {
      ...transition,
      clipPath: `circle(${cursorSize * scale}px at ${posX}px ${posY}px)`
    });

    setPrevPos({
      x: posX,
      y: posY
    });

    return () => {
      setCursorTimeout(
        setTimeout(() => {
          gsap.to(`.spotlight-clipper`, {
            ...transition,
            clipPath: `circle(${cursorSize}px at ${posX}px ${posY}px)`
          });
        }, 100)
      );
    };
  }, [isDesktop, pos, scrollY, windowSize]);

  // ---------------------------------------------------------------------------
  // render

  const innerContent = (
    <Grid>
      <div className={cn(styles.overlayHeader)}>
        <SVG svg="wordmark" className={styles.wordmark} />
        <h1 className={cn(`h2`)}>Toolkit™</h1>
      </div>

      <div className={cn(styles.overlayContent)}>
        <p className={cn(`h2`)}>
          Lucid reimagine services that humans use every day with technology
          that optimises for efficiency and transparency because seeing is
          believing.
        </p>
      </div>
    </Grid>
  );

  return (
    <section
      ref={containerRef}
      className={styles.container}
      onMouseMove={updatePos}
    >
      <div className={styles.background}>
        <NoiseSpotlight />
      </div>

      <div className={cn(styles.overlay, `spotlight-clipper`)}>
        {innerContent}
      </div>

      <div className={cn(styles.overlay, styles.overlayBack)}>
        {innerContent}
      </div>
    </section>
  );
};

export default Spotlight;
