import React, { useState, useEffect } from "react";
import { Button, Grid } from "~components";
import COLORS from "~data/colors.json";
import cn from "classnames";
import { capitalizeString } from "~utils/helpers";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * ASG Editor
 */
const ASGColors = ({ onUpdate = () => {} }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [updatedColors, setUpdatedColors] = useState(COLORS);

  // ---------------------------------------------------------------------------
  // variables

  const colorKeys = Object.keys(updatedColors);
  const readOnlyColorKeys = Array(10)
    .fill(null)
    .map((_, index) => {
      if (index === 0) {
        return `black`;
      }

      return `black-${index * 10}`;
    });
  readOnlyColorKeys.push(`white`);
  readOnlyColorKeys.push(`ux-error`);
  readOnlyColorKeys.push(`ux-success`);

  // ---------------------------------------------------------------------------
  // methods

  const addColor = (e) => {
    e.preventDefault();

    if (!e?.target?.[0]?.value) {
      return;
    }

    const label = e.target[0].value;
    const key = label.replaceAll(` `, `-`).toLowerCase();

    if (updatedColors?.[key]) {
      return;
    }

    e.target[0].value = ``;

    setUpdatedColors({
      ...updatedColors,
      [key.replaceAll(` `, `-`).toLowerCase()]: {
        id: key,
        swatch: label,
        mono: false,
        hex: `#000000`,
        display: `light`
      }
    });
  };

  const deleteColor = (key) => {
    if (!updatedColors?.[key]) {
      return;
    }

    const colorsClone = JSON.parse(JSON.stringify(updatedColors));

    delete colorsClone?.[key];

    setUpdatedColors(colorsClone);
  };

  const onChange = (e) => {
    const colorKey = e.currentTarget.name;

    const objectKey = e.currentTarget.getAttribute(`objectKey`);
    if (!objectKey) {
      return;
    }

    const colorData = updatedColors?.[colorKey];
    if (!colorData) {
      return;
    }

    setUpdatedColors({
      ...updatedColors,
      [colorKey]: {
        ...colorData,
        [objectKey]:
          e.currentTarget.type === `checkbox`
            ? e.currentTarget.checked
            : e.currentTarget.value
      }
    });
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    onUpdate(updatedColors);
  }, [updatedColors]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Grid>
      <div className={styles.colorsReadOnly}>
        <h2 className={cn(styles.colorsHeading, `h2`)}>Theme Colors</h2>

        <ul>
          {readOnlyColorKeys.map((readOnlyColorKey) => {
            const color = updatedColors?.[readOnlyColorKey];

            if (!color) {
              return null;
            }

            return (
              <li className={styles.tableRow}>
                <div className={styles.color}>
                  <div className={styles.colorInfo}>
                    <div
                      className={styles.colorIcon}
                      style={{ background: color?.hex || `white` }}
                    />

                    <p className="b1">
                      <span>{color.id}</span>
                      <span className={styles.colorIconHex}>
                        &nbsp;({color.hex})
                      </span>
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div className={styles.colorsCustom}>
        <h2 className={cn(styles.colorsHeading, `h2`)}>Sanity Colors</h2>

        <ul>
          {colorKeys.map((colorKey) => {
            const color = updatedColors?.[colorKey];
            const defaultColor = readOnlyColorKeys?.includes(colorKey);

            if (!color || defaultColor) {
              return null;
            }

            const objectKeys = Object.keys(color);

            return (
              <li className={styles.tableRow}>
                <div className={styles.color}>
                  <div className={styles.colorInfo}>
                    <div
                      className={styles.colorIcon}
                      style={{ background: color?.hex || `white` }}
                    />

                    <p className="b1">
                      <span>{color.id}</span>
                      <span className={styles.colorIconHex}>
                        &nbsp;({color.hex})
                      </span>
                    </p>
                  </div>

                  <button type="button" onClick={() => deleteColor(colorKey)}>
                    <span>❌</span>
                  </button>
                </div>

                <div className={styles.colorEditor}>
                  {objectKeys.map((objectKey) => {
                    if (!objectKey || objectKey === `id`) {
                      return null;
                    }

                    const value = color[objectKey];

                    let inputJSX;

                    switch (objectKey) {
                      case `mono`:
                        inputJSX = (
                          <input
                            className="b2"
                            name={colorKey}
                            objectKey={objectKey}
                            defaultValue={value}
                            onChange={onChange}
                            type="checkbox"
                            defaultChecked={value === true}
                          />
                        );

                        break;

                      case `display`:
                        inputJSX = (
                          <select
                            className="b2"
                            name={colorKey}
                            objectKey={objectKey}
                            onChange={onChange}
                          >
                            {[`Dark`, `Light`].map((theme) => (
                              <option
                                key={`select-${theme}`}
                                selected={theme?.toLowerCase() === value}
                              >
                                {theme}
                              </option>
                            ))}
                          </select>
                        );

                        break;

                      default:
                        inputJSX = (
                          <input
                            className="b2"
                            name={colorKey}
                            objectKey={objectKey}
                            defaultValue={value}
                            onChange={onChange}
                            type="text"
                          />
                        );
                        break;
                    }

                    return (
                      <div
                        key={`object-${objectKey}`}
                        className={styles.colorEditorRow}
                      >
                        <p
                          className={cn(`caption`, styles.colorEditorRowLabel)}
                        >
                          {capitalizeString(objectKey)}:
                        </p>

                        <label
                          htmlFor={objectKey}
                          className={styles.colorEditorInput}
                        >
                          {inputJSX}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>

        <div className={styles.addColor}>
          <h2 className={cn(styles.addColorHeading, `h3`)}>Add new</h2>

          <p className={cn(styles.addColorDescription, `b2`)}>
            Enter a name for your new colour. Once you press the button, it will
            appear above for editing.
          </p>

          <form onSubmit={addColor}>
            <label htmlFor="key" className={styles.addColorFormLabel}>
              <input
                className="b2"
                name="key"
                type="text"
                placeholder={`Color name (e.g. "Burnt Sienna").`}
              />
            </label>

            <Button buttonType="submit" className={styles.addColorFormSubmit}>
              <span className="button-text">ADD</span>
            </Button>
          </form>
        </div>
      </div>
    </Grid>
  );
};

export default ASGColors;
