import React from "react";
import { Grid, Image, IntersectionAnimation, SliceConfig } from "~components";
import * as styles from "./styles.module.scss";

const ImageGrid = ({ data: { images, sliceConfig } }) => {
  if (!images?.[0]) {
    return null;
  }

  return (
    <section>
      <IntersectionAnimation>
        <SliceConfig config={sliceConfig}>
          <Grid>
            {images.map((image, i) => (
              <IntersectionAnimation
                key={i}
                delay={i * 100}
                className={styles.imageContainer}
              >
                <Image image={image} className={styles.image} />
              </IntersectionAnimation>
            ))}
          </Grid>
        </SliceConfig>
      </IntersectionAnimation>
    </section>
  );
};

export default ImageGrid;
