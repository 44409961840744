import React from "react";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import { BlockContent, Button, Grid, Link, SliceConfig } from "~components";

import * as styles from "./styles.module.scss";

const BorderArticle = ({
  id,
  className,
  asFooter,
  data: { bordered, headingStyle, heading, _rawBody, sliceConfig }
}) => {
  const [ref, inView] = useInView({
    threshold: 0.3
  });

  return (
    <section ref={ref} sliceid={id}>
      <SliceConfig config={sliceConfig}>
        <div className={cn(className, styles.container)}>
          <Grid>
            {bordered && (
              <div
                className={cn(styles.separator, {
                  [styles.separatorVisible]: inView
                })}
              />
            )}

            <header className={cn(styles.header)}>
              {heading && (
                <h3
                  className={cn(
                    styles.heading,
                    headingStyle?.style
                      ? headingStyle.style.toLowerCase()
                      : `caption`,
                    {
                      [styles.headingVisible]: inView
                    }
                  )}
                >
                  {heading}
                </h3>
              )}
            </header>

            <div
              className={styles.content}
              style={{
                opacity: inView ? 1 : 0
              }}
            >
              {(asFooter && (
                <>
                  <p className="b1">
                    This website is dynamic, and will be regularly updated.
                    Please make sure you are working with the most recent
                    version of assets. If you have questions, ideas, or
                    insights, after reading this guide, please get in touch with
                    us below:
                  </p>

                  <br />

                  <Link to="mailto:hello@loveandmoney.agency">
                    <Button color="white">
                      <span className="button-text">Email Us</span>
                    </Button>
                  </Link>
                </>
              )) || (
                <>
                  {_rawBody && (
                    <div className={[styles.rawBody]}>
                      <BlockContent blocks={_rawBody} />
                    </div>
                  )}
                </>
              )}
            </div>
          </Grid>
        </div>
      </SliceConfig>
    </section>
  );
};

export default BorderArticle;
