import React, { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import {
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  RawShaderMaterial,
  AdditiveBlending,
  PlaneGeometry,
  TextureLoader,
  DoubleSide
} from "three";
import * as THREE from "three";
import fogVertex from "./glsl/fog.vertex";
import fogFragment from "./glsl/fog.fragment";

import * as styles from "./styles.module.scss";

const HTMLPixelPuzzleSquare = () => {
  let i;

  return (
    <>
      <mesh>
        <Html fullscreen>
          <div className={styles.container}>
            <div className={styles.testBox}>
              <></>
            </div>
          </div>
        </Html>
      </mesh>
    </>
  );
};

export default HTMLPixelPuzzleSquare;
