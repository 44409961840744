// For use with Quantity component
import { useEffect, useState } from "react";

const useQuantity = (initialQuantity, minimumValue = 1) => {
  const [quantity, setQuantity] = useState(initialQuantity || 0);
  const [max, setMax] = useState(null);

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const increment = () =>
    setQuantity((prev) => {
      if (!max || prev < max) return prev + 1;
      return prev;
    });
  const decrement = () =>
    setQuantity((prev) => {
      if (prev > minimumValue) return prev - 1;
      return minimumValue;
    });

  return { quantity, setQuantity, increment, decrement, max, setMax };
};

export default useQuantity;
