/* eslint-disable import/no-extraneous-dependencies */

import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { useWindowDimensions } from "~hooks";
import { PixelPuzzleSquare } from "~components";

import * as styles from "./styles.module.scss";

const ThreePixelPuzzle = ({ shouldRender = true }) => {
  const { windowSize } = useWindowDimensions();

  const scale = windowSize.width / 700;

  return (
    <>
      {shouldRender && (
        <div className={styles.canvas}>
          <Canvas
            orthographic
            shadowMap
            camera={{ zoom: 100, position: [0, 0, 100] }}
            style={{ background: `#f0f0f0` }}
          >
            <ambientLight intensity={1} />

            <pointLight
              position={[0, 10, 30]}
              rotation={[0, 90, 0]}
              intensity={1}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              castShadow
            />

            <Suspense>
              <PixelPuzzleSquare scale={scale} />
            </Suspense>
          </Canvas>
        </div>
      )}
    </>
  );
};

export default ThreePixelPuzzle;
