// extracted by mini-css-extract-plugin
export var addColor = "styles-module--addColor--bf80f";
export var addColorDescription = "styles-module--addColorDescription--8316e";
export var addColorFormLabel = "styles-module--addColorFormLabel--166af";
export var addColorFormSubmit = "styles-module--addColorFormSubmit--a75ed";
export var addColorHeading = "styles-module--addColorHeading--d9c86";
export var color = "styles-module--color--2250f";
export var colorEditor = "styles-module--colorEditor--9ebfb";
export var colorEditorInput = "styles-module--colorEditorInput--eb6d6";
export var colorEditorRow = "styles-module--colorEditorRow--f3347";
export var colorEditorRowLabel = "styles-module--colorEditorRowLabel--7fdf1";
export var colorIcon = "styles-module--colorIcon--a239f";
export var colorIconHex = "styles-module--colorIconHex--d707f";
export var colorInfo = "styles-module--colorInfo--e1f8d";
export var colorsCustom = "styles-module--colorsCustom--bc7f5";
export var colorsHeading = "styles-module--colorsHeading--30afb";
export var colorsReadOnly = "styles-module--colorsReadOnly--7ad78";
export var tableRow = "styles-module--tableRow--d1559";