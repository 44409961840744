import React from "react";
import { Grid, Image, IntersectionAnimation, SliceConfig } from "~components";
import * as styles from "./styles.module.scss";

const SimpleImage = ({ data: { image, sliceConfig } }) => {
  if (!image) {
    return null;
  }

  return (
    <section>
      <IntersectionAnimation>
        <SliceConfig config={sliceConfig}>
          <div className={styles.container}>
            <Grid>
              <figure className={styles.imageContainer}>
                <Image className={styles.image} image={image} />
              </figure>
            </Grid>
          </div>
        </SliceConfig>
      </IntersectionAnimation>
    </section>
  );
};

export default SimpleImage;
