/* eslint-disable camelcase */
import React, { createContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { globalHistory } from "@reach/router";
import * as styles from "~styles/disableScroll.module.scss";

export const AppContext = createContext({});

/** ============================================================================
 * @context
 * Global application data.
 */
const AppProvider = ({ children }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const [pathname, setPathname] = useState(null);

  // ---------------------------------------------------------------------------
  // methods

  // ...

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window !== `undefined` && window?.location?.pathname) {
      setPathname(window.location.pathname);
    }

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  // Close menu on page change
  useEffect(() => {
    if (isHeaderOpen) {
      setIsHeaderOpen(false);
    }
  }, [pathname]);

  // Disable page scroll
  useEffect(() => {
    if (isHeaderOpen) {
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.classList.remove(styles.disableScroll);
    }
  }, [isHeaderOpen]);

  // ---------------------------------------------------------------------------
  // render

  const contextProps = useMemo(() => ({
    isHeaderOpen,
    setIsHeaderOpen,
    pathname
  }));

  return (
    <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
