import React, { useRef, useState, useEffect } from "react";
import { SVG } from "~components";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const Accordion = ({ title, content }) => {
  /** ============================================================================
   * @component
   * Accordian which can reveal information when clicked and opened.
   *
   * @param  {string}  	title		 		Header text
   * @param  {string}  	content 		Text revealed when opened
   * @return {node}
   */

  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(60);
  const contentRef = useRef();

  const calculateContentHeight = () => {
    const height = contentRef.current.offsetHeight;
    setContentHeight(height);
  };

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  return (
    <>
      <button
        className={styles.button}
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
        aria-expanded={isOpen}
      >
        <span className={cn(`b1`, styles.buttonText)}>{title}</span>
        <SVG
          svg="ArrowDown"
          className={cn(styles.arrow, { [styles.rotate]: isOpen })}
        />
      </button>
      <div
        className={styles.contentContainer}
        aria-hidden={!isOpen}
        style={{ height: `${isOpen ? contentHeight : 8}px` }}
      >
        <p
          ref={contentRef}
          className={cn(`b2`, styles.contentText, { [styles.visible]: isOpen })}
        >
          {content}
        </p>
      </div>
    </>
  );
};

export default Accordion;
