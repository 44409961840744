import React, { useState } from "react";
import cn from "classnames";
import * as C from "~components";
import { camelCaseToTitleCase } from "~utils/helpers";
import * as styles from "./styles.module.scss";

const WebComponents = () => {
  const components = [
    `button`,
    `textInput`,
    `quantity`,
    `colorPicker`,
    `accordion`
  ];

  const [activeComponent, setActiveComponent] = useState(components[0]);

  const selectComponent = (component) => {
    setActiveComponent(component);
  };

  const componentToRender = () => {
    switch (activeComponent) {
      case `button`:
        return <C.PgButton />;
      case `textInput`:
        return <C.PgTextInput />;
      case `quantity`:
        return <C.PgQuantity />;
      case `colorPicker`:
        return <C.PgColorPicker />;
      case `accordion`:
        return <C.PgAccordion />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      {components.map((component) => (
        <button
          type="button"
          className={cn(styles.button, {
            [styles.selected]: activeComponent === component
          })}
          onClick={() => selectComponent(component)}
          key={component}
        >
          {camelCaseToTitleCase(component)}
        </button>
      ))}
      <div className={styles.partition} />

      <div>{componentToRender()}</div>
    </div>
  );
};

export default WebComponents;
