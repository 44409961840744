// extracted by mini-css-extract-plugin
export var background = "styles-module--background--8074e";
export var container = "styles-module--container--f2e54";
export var desktop = "1340px";
export var giant = "2200px";
export var headerAppear = "styles-module--header-appear--c25f9";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var overlay = "styles-module--overlay--f03b9";
export var overlayBack = "styles-module--overlayBack--bec9c";
export var overlayContent = "styles-module--overlayContent--7ba80";
export var overlayHeader = "styles-module--overlayHeader--9810d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var wordmark = "styles-module--wordmark--21e8b";