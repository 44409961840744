import React from "react";
import { Quantity } from "~components";
import { usePlayground, useQuantity } from "~hooks";

const PgQuantity = () => {
  const { renderPlayground } = usePlayground();
  const { quantity, increment, decrement } = useQuantity(1);

  return (
    <>
      {renderPlayground(
        <Quantity
          value={quantity}
          handleIncrement={increment}
          handleDecrement={decrement}
        />
      )}
    </>
  );
};

export default PgQuantity;
