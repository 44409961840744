// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--799e7";
export var appearDown = "styles-module--appear-down--33d5f";
export var appearLeft = "styles-module--appear-left--1c127";
export var appearRight = "styles-module--appear-right--712b9";
export var appearUp = "styles-module--appear-up--fed42";
export var container = "styles-module--container--89595";
export var content = "styles-module--content--e736f";
export var contentVisible = "styles-module--contentVisible--4862f";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--88fa7";
export var heading = "styles-module--heading--309e3";
export var headingVisible = "styles-module--headingVisible--2dc96";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var separator = "styles-module--separator--cb899";
export var separatorVisible = "styles-module--separatorVisible--c32a8";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";