import React, { useState } from "react";
import { ColorPicker } from "~components";
import { usePlayground } from "~hooks";

const PgTextInput = () => {
  const schemaFields = [
    {
      title: `square`,
      type: `toggle`
    },
    {
      title: `vertical`,
      type: `toggle`
    },
    {
      title: `display`,
      type: `toggle`
    }
  ];

  const { config, renderPlayground } = usePlayground(schemaFields);

  const [variants, setVariants] = useState([
    { hex: `#ff0000`, title: `red`, selected: false },
    { hex: `#ffae00`, title: `red`, selected: false },
    { hex: `#73ff00`, title: `red`, selected: false },
    { hex: `#00d9ff`, title: `red`, selected: false },
    { hex: `#001aff`, title: `red`, selected: false }
  ]);

  const handleOnChange = (selectedVariant) => {
    setVariants((prev) =>
      prev.map((variant) => ({
        ...variant,
        selected: variant.hex === selectedVariant.hex
      }))
    );
  };

  return (
    <>
      {renderPlayground(
        <ColorPicker
          {...config}
          onChange={handleOnChange}
          variants={variants}
        />
      )}
    </>
  );
};

export default PgTextInput;
