// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var gatsbyImageWrapper = "styles-module--gatsby-image-wrapper--3daa3";
export var giant = "2200px";
export var image = "styles-module--image--f527c";
export var imageContainer = "styles-module--imageContainer--d9702";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";