import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const SliceConfig = ({ children, config }) => {
  const { backgroundColor, textColor, slicePadding } = config || {};
  const {
    paddingTop = `regular`,
    paddingBottom = `regular`,
    paddingX = `regular`
  } = slicePadding || {};

  return (
    <div
      style={{
        color: textColor?.hex || `black`,
        backgroundColor: backgroundColor?.hex || `transparent`
      }}
      className={cn(styles.container, {
        [styles.paddingTopRegular]: paddingTop === `regular`,
        [styles.paddingTopSmall]: paddingTop === `small`,
        [styles.paddingBottomRegular]: paddingBottom === `regular`,
        [styles.paddingBottomSmall]: paddingBottom === `small`,
        [styles.paddingXRegular]: paddingX === `regular`,
        [styles.paddingXSmall]: paddingX === `small`
      })}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default SliceConfig;
