import React from "react";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import { BlockContent, Grid, SliceConfig } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const TextArticle = ({ id, className, data: { _rawBody, sliceConfig } }) => {
  const [ref, inView] = useInView({
    threshold: 0
  });

  //

  return (
    <section ref={ref} sliceid={id} className={cn(className, styles.container)}>
      <SliceConfig config={sliceConfig}>
        <Grid>
          <div
            className={cn(styles.content, { [styles.contentVisible]: inView })}
          >
            {_rawBody && (
              <div className={[styles.rawBody]}>
                <BlockContent blocks={_rawBody} />
              </div>
            )}
          </div>
        </Grid>
      </SliceConfig>
    </section>
  );
};

export default TextArticle;
